import React from 'react';
import { Button, LinkHandler } from '@/components/atoms';
import styled from 'styled-components';
import { makeLink } from '@/utils/Link';
import { IBtnCmp } from './IBtnCmp';

const CustomButton = styled.div`
  display: inline-block;
  a {
    text-align: center;
    color: inherit;
  }
`;

const Btn: React.FC<IBtnCmp> = props => {
  const { label, link, variant, size } = props.fields;

  let url = '';
  let target = '';

  if (link) {
    url = makeLink(link.fields.type, link.fields.href, null);
    target = link.fields.target ? link.fields.target : '_self';
  }

  return (
    <div>
      <CustomButton>
        <Button variant={variant} size={size}>
          <LinkHandler href={url} source={'cmsLink-button'}>
            <a target={target}>{label}</a>
          </LinkHandler>
        </Button>
      </CustomButton>
    </div>
  );
};

export default Btn;
